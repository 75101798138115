<template>
    <div class="music-player">
        <div v-if="initalised" @click="toggleMute()" class="cursor-pointer audio-control-container btn btn--sm hidden md:inline-flex bg-yellow hover:bg-beige" v-bind:class="{ muted: muted }">
            <span class="icon-mute" v-if="muted"><img src="icons/speaker-muted.svg" alt="Muted"></span>
            <span class="icon-mute" v-else><img src="icons/speaker.svg" alt="Unmuted"></span>
            MUTE AUDIO
        </div>
        <div class="song-info">
            <div>
                <div class="streaming-provider">
                    <span v-if="userType === 'Spotify' || userType ==='SpotifyPremium' || userType ==='Free'">
                        <i class="fab fa-spotify"></i>
                    </span>
                    <span v-else>
                        <i class="fab fa-apple"></i>
                    </span>
                </div>
                <span class="song-title">
                    {{currentTrack['songname']}}
                </span>

                <span class="audio-element" v-if="userType === 'Free'">
                    <audio ref="audioPlayer" autoplay muted @ended="playNext" >
                        <source :src="currentTrack['songurl']" type="audio/mpeg">
                    </audio>
                </span>

            </div>
            <div class="music-player-loader" v-if="loadingTrackData === true || error">
                <p v-if="!error">Gathering music player data...</p>
                <p v-else @click="initPlayer()" class="cursor-pointer">Whoops, there was a problem with the player. <span> Click here to retry</span></p>
            </div>
        </div>

    </div>

</template>

<script>
    import { EventBus } from '@/eventBus';
    import axios from 'axios';

    export default {
        name: 'MusicPlayer',
        data: function () {
            return {
                userType: '',
                muted: false,
                initalised: false,
                currentTrackIndex: 0,
                currentTrack: [{
                    'songname': '',
                    'url': ''
                }],
                spotifyDeviceID: '',
                trackData: [],
                loadingTrackData: true,
                error: null
            }
        },
        created() {

            //Straight forward, give the player a specific track ID to play.
            EventBus.$on('playTrack', (ID) => {
                this.loadTrack(ID);
            });

            //Incase we want to force the music player to init - for instance, on clicking the door knocker.
            EventBus.$on('initMusicPlayer', () => {
                console.log('init music player was called');
                this.initPlayer();
            });


            // When the compoent loads, we check the cookies to see what user type they are.
            this.getUserType();

            //ALWAYS load the Spotify SDK
            // This is because users can switch between 'Free'/'Apple'/'Spotify' at will.
            // this.loadSpotifySDK();
            setTimeout(() => this.loadSpotifySDK(), 1000);

            // We wait 1 second - to init the player.
            // This is because chrome requires users to have clicked on the document before any audio can be played.
            setTimeout(() => this.initPlayer(), 3000);
        },
        methods: {
            //------------------
            // CREATE / BOOT THE MUSIC PLAYER
            //------------------
            initPlayer: function() {

                console.log('Init player!')
                let self = this;
                // console.log(self.initalised)
                if (self.initalised === false) {
                    self.loadSpotifySDK()
                    // Load the track list data, then...
                    this.getTrackListData().then(function (res) {
                        // Store the track list at the component level.
                        self.trackData = res

                        // If the user is Apple - we want to add an event listener.
                        // This is so we can check when to play the next song.
                        if (self.userType === 'Apple') {
                            appleMusicKit.amk.player._registry.playbackTimeDidChange.push(function (event) {
                                if (typeof event !== 'undefined' && event.currentPlaybackTimeRemaining === 0 && isNaN(event)) {
                                    self.playNext()
                                }
                            });

                            appleMusicKit.search('Mark Lanegan')
                            console.log(appleMusicKit.search('Mark Lanegan'))
                        }

                        // console.log('test')
                        self.initalised = true
                        // After getting the track data, we wait 500ms to play a song.
                        setTimeout(() => self.loadTrack(), 500)
                    })
                }
            },


            //-------------------
            // GET USER TYPE
            //-------------------
            getUserType: function(){

                // We store our 'User Type' in cookies on the domain.
                // When you auth a user via a music service, you should create/set/update the userType cookie.

                // IMPORTANT! -- Spotify premium cookies should be set to expire in 60 minutes.
                // That's the amount of time we have before Spotify needs the user to Reauth.


                if ( this.$cookies.isKey("userType")){
                    this.userType = this.$cookies.get("userType");

                    //SPOTIFY
                    if(this.userType === "Spotify"){

                        //IN YOUR AUTH METHOD, THIS COOKIE WILL EITHER BE "premium" or null.
                        if(this.$cookies.get("spotifyAccountPremium") === "premium"){
                            this.userType = "SpotifyPremium";
                        }else{
                            this.userType = "Free";
                        }
                    }

                    //APPLE
                    else if(this.userType === 'Apple'){
                        this.userType = "Apple";
                    }

                    //If neither, user must be 'Free'.
                    else{
                        this.userType = 'Free';
                    }
                }
                // USER HAS NO COOKIE.
                else{
                    this.userType = "Free";
                }
            },


            //--------------------
            // LOAD THE TRACK DATA
            //--------------------
            getTrackListData: async function() {
                this.loadingTrackData = true;
                try {
                    let res = await axios({
                        url: '/data/tracks/tracks.json',
                        method: 'get',
                        timeout: 8000,
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    this.loadingTrackData = false;
                    return res.data;
                }
                catch (error) {
                    this.error = 'There was an error loading this data. We are sorry.';
                }
            },



            //------------------------------
            // The Meat and bones of my baby
            //------------------------------
            loadTrack: function(ID) {
                if(this.loadingTrackData === false){

                    if (this.initalised === false) {
                        this.initPlayer();
                    }

                    // Check user Type again, as the user can always update this // chane route.
                    // You never know, you just never know.
                    this.getUserType();
                    let userType = this.userType;
                    var indexOfSongToPlay;
                    var trackType;
                    let songname;
                    let songURL;

                    //Useful to debug -
                    //console.log('The music player is playing in mode : ' + userType);

                    //---------
                    // If you don't pass an ID to this method, we generate one randomly.
                    //--------
                    if(typeof ID !== 'undefined'){
                        indexOfSongToPlay = ID;
                    }else{
                        indexOfSongToPlay = Math.floor(Math.random() * this.trackData.length);
                    }

                    //----------
                    // DECIDE HOW TO PLAY THE MUSIC
                    //----------
                    if(userType === 'Apple'){
                        songname =  this.trackData[indexOfSongToPlay]['songname'];
                        songURL =   this.trackData[indexOfSongToPlay]['apple'];


                        //Check to see if it has a valid apple ID, as some do not
                        if(!songURL){
                            songURL = '3243438';
                            songname = 'Apple Baby';
                        }else{
                            // you might not need to split this, but songURL for apple should just be the trackID.
                            songURL = songURL.split('?i=')[1];
                        }

                        appleMusicKit.playItem(songURL,'song');
                        this.$set(this.currentTrack, 'songname', songname);
                        this.$set(this.currentTrack, 'songurl',  songURL);
                        EventBus.$emit('songUpdated', indexOfSongToPlay);
                    } 

                    //If the user is a Spotify Premium member, use the Spotify SDK
                    if(userType === 'SpotifyPremium'){
                        songname =  this.trackData[indexOfSongToPlay]['songname'];
                        songURL =   this.trackData[indexOfSongToPlay]['spotify'];

                        //Set the objects props, forcing vue to update
                        this.$set(this.currentTrack, 'songname', songname);
                        this.$set(this.currentTrack, 'songurl', songURL);
                        this.spotifyPlay(this.spotifyDeviceID, songURL);
                    } 

                    //If the user is free / hatch - We use the Spotify Free / Preview player.
                    if(userType === 'Free'){
                        songname =  this.trackData[indexOfSongToPlay]['songname'];
                        songURL =   this.trackData[indexOfSongToPlay]['hatch'];

                        // If the URL is not valid, we force it to use the ID of a song we KNOW has a spotify link.
                        if(songURL === undefined){
                            indexOfSongToPlay = 1;
                            songURL =  this.trackData[1][trackType];
                        }

                        //Set the objects props, forcing vue to update
                        this.$set(this.currentTrack, 'songname', songname);
                        this.$set(this.currentTrack, 'songurl', songURL);

                        this.$refs.audioPlayer.load();
                        var promise = this.$refs.audioPlayer.play();
                        var self = this;
                        if (promise !== undefined) {
                            promise.then(_ => {
                                self.error = null;
                                EventBus.$emit('songUpdated', indexOfSongToPlay);
                            }).catch(error => {
                                console.log(error);
                                //DOM Exception literally means the user did not interact with any dom elements/click on the page.
                                self.error = "Dom exception";
                            });
                        } else {
                            console.log('Dom Exception');
                        }
                    }
                }
            },

            playNext: function() {
                this.loadTrack();
            },


            //---------
            // MUTE / UNMUTE EACH SERVICE
            //----------
            toggleMute: function() {
                if(this.userType === 'Apple'){
                    if ( this.muted === true ){
                        appleMusicKit.amk.player.volume = 1;
                    }else{
                        appleMusicKit.amk.player.volume = 0;
                    }
                }
                if(this.userType === 'SpotifyPremium'){
                    console.log(this.muted);
                    if ( this.muted === true ){
                        this.spotifyVolume(this.spotifyDeviceID, 100);
                    }else{
                        this.spotifyVolume(this.spotifyDeviceID, 0);
                    }
                }
                if(this.userType === 'Free'){
                    if ( this.muted === true ){
                        this.$refs.audioPlayer.muted = false;
                    }else{
                        this.$refs.audioPlayer.muted = true;
                    }
                }
                this.muted = !this.muted;
            },

            //--------------------
            // LOAD SPOTIFY SDK
            //--------------------
            loadSpotifySDK: function(){
                const token = this.$cookies.get('spotifyAuthToken');
                const player = new Spotify.Player({
                    name: 'Desert Sessions player',
                    getOAuthToken: cb => { cb(token); }
                });

                // Error handling
                player.on('initialization_error', e => console.error(e));
                player.on('authentication_error', e => console.error(e));
                player.on('account_error', e => console.error(e));
                player.on('playback_error', e => console.error(e));


                // Playback status updates
                player.on('player_state_changed', state => {
                    this.$set(this.currentTrack, 'songname', state.track_window.current_track.name);
                    if(state.paused && state.position === 0 && state.restrictions.disallow_resuming_reasons &&
                        state.restrictions.disallow_resuming_reasons[0] === "not_paused"){
                        this.playNext();
                    }
                });

                // Ready
                player.on('ready', data => {
                    this.spotifyDeviceID = data.device_id;
                });

                // Not Ready -- if a device goes offline
                player.addListener('not_ready', ({ device_id }) => {
                    console.log('spotify SDK player is NOT ready ');
                });

                // Connect to the player!
                player.connect();
            },


            //------------------
            // SPOTIFY WEB SDK PLAYER
            //------------------
            // - Device_ID is the Spotify generated ID for your player. This should never be null.
            // - track_id is the ID of the track within your track.json file.
            //
            spotifyPlay: function(device_id, track_id){
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ this.$cookies.get("spotifyAuthToken")
                };

                const data = {"uris": ["spotify:track:"+ track_id]};

                if ( device_id === null ) {
                    this.initPlayer();
                } else {
                    axios.put("https://api.spotify.com/v1/me/player/play?device_id=" + device_id, data, {
                        headers: headers
                    })
                    .then((response) => {

                    })
                    .catch((error) => {
                        console.log(error);
                    })
                }
            },

            //------------------
            // SPOTIFY WEB SDK VOLUME CONTROL
            //------------------
            //It's called in the mute method, ok...
            spotifyVolume: function(device_id, volume){
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+ this.$cookies.get('spotifyAuthToken')
                };

                const data = { 'volume_percent': volume };

                axios.put("https://api.spotify.com/v1/me/player/volume?volume_percent=" + volume + "&device_id=" + device_id, data, {
                        headers: headers
                })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                })
            },
        },
    }
</script>

<style scoped>
    .song-info {
        position: fixed;
        bottom: 10px;
        left: 10px;
        background-color: #f00;
        color: #000;
        display: none;
    }

    .icon-mute {
        margin-right: 5px;
    }
</style>
